import {
  EAppActionType,
  IAppRedirectGetAction,
  IAppSendRequestFormPostAction,
  IAppSetAction,
  IAppState,
  IAppPersonalInformationGetAction
} from '@redux/app/types';
import {IRequestFormData, IRequestFormPayload} from '@redux/types';


export const setApp = (payload: IAppState): IAppSetAction => ({
  type: EAppActionType.SET_APP,
  payload
})

export const getAppRedirect = (slug: string): IAppRedirectGetAction => ({
  type: EAppActionType.GET_APP_REDIRECT,
  payload: {slug}
})

export const getPersonalInformation = (): IAppPersonalInformationGetAction => ({
  type: EAppActionType.GET_PERSONAL_INFORMATION
})

export const sendRequestForm = (payload: IRequestFormPayload): IAppSendRequestFormPostAction => ({
  type: EAppActionType.SEND_REQUEST_FORM,
  payload
})

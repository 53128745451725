import {wrapper} from '@redux/index';
import type {AppProps} from 'next/app';
import {Provider} from 'react-redux';
import '@common/sass/style.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import {settings} from '@common/settings';
import {YMInitializer} from 'react-yandex-metrika';


const ProjectApp = ({Component, ...rest}: AppProps) => {
  const {store, props} = wrapper.useWrappedStore(rest)
  
  return (
    <Provider store={store}>
      {settings.keys.ymId && (
        <YMInitializer
          accounts={[settings.keys.ymId]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
          }}
        />
      )}
      <Component {...props.pageProps} />
    </Provider>
  )
}

export default ProjectApp

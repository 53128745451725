import * as process from 'process';

// backend
const auth = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken'
}

const api = {
  methodsWithBody: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
  backendUrl: process.env.NEXT_PUBLIC_BACKEND_URL || '',
  refreshTokenUrl: process.env.NEXT_PUBLIC_REFRESH_TOKEN_URL || '',
  url: `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}${process.env.NEXT_PUBLIC_API_PATH || ''}`,
  externalApiKeys: {
    // ...your services api keys
  }
}

const project = {
  name: process.env.NEXT_PUBLIC_PROJECT_NAME || 'My project',
  draftMode: process.env.NEXT_PUBLIC_DRAFT_MODE === 'true'
}

const keys = {
  ymId: process.env.NEXT_PUBLIC_YM_ID ? Number(process.env.NEXT_PUBLIC_YM_ID) : null,
  yMapsApiKey: process.env.NEXT_PUBLIC_YMAPS_API_KEY ?? ''
}

export const settings = {
  auth,
  api,
  project,
  keys
}

import {EPage, TLang} from '@common/types';
import {IPersonalInformation, IRequestFormData, IRequestFormPayload, ISection, IStrapiVideo} from '@redux/types';

export enum EAppActionType {
  GET_APP = 'GET_APP',
  SET_APP = 'SET_APP',
  GET_PERSONAL_INFORMATION = 'GET_PERSONAL_INFORMATION',
  GET_APP_REDIRECT = 'GET_APP_REDIRECT',
  SEND_REQUEST_FORM = 'SEND_REQUEST_FORM'
}

export interface IRequestFormProps {
  open?: boolean
  status?: null | 'loading' | 'success' | 'error'
  isClear?: boolean
}

export interface IModalRequestFormProps extends IRequestFormProps {
  form: null | 'header' | 'request' | 'download'
}

export interface IAppState {
  page?: EPage | null
  locale?: TLang
  section?: ISection | null
  personalInformation?: IPersonalInformation
  requestForm?: IRequestFormProps
  modalForm?: IModalRequestFormProps
  blockForm?: Omit<IRequestFormProps, 'open'>
  modalVideo?: {
    open?: boolean
    video?: IStrapiVideo | null
  }
  noEng?: boolean
  draftMode?: boolean
}

export interface IAppSetAction {
  type: EAppActionType.SET_APP,
  payload: IAppState
}

export interface IAppGetAction {
  type: EAppActionType.GET_APP
}

export interface IAppPersonalInformationGetAction {
  type: EAppActionType.GET_PERSONAL_INFORMATION
}

export interface IAppRedirectGetAction {
  type: EAppActionType.GET_APP_REDIRECT,
  payload: {
    slug: string
  }
}

export interface IAppSendRequestFormPostAction {
  type: EAppActionType.SEND_REQUEST_FORM,
  payload: IRequestFormPayload
}

export type IAppActions = IAppSetAction | IAppGetAction | IAppPersonalInformationGetAction | IAppRedirectGetAction

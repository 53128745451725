import {URLSearchParams} from 'url';
import {IParam, IStrapiQuery, TLang} from '@common/types';
import {
  CardType,
  EBlockType,
  EComponent,
  IAward,
  IAwardResponse,
  IBlog,
  IBlogResponse,
  ICard,
  ICardResponse,
  ICaseListComponent,
  ICaseListResponse,
  IEntityDetail,
  IEntityDetailResponse,
  IGridComponent,
  IGridComponentResponse,
  IHomePage,
  IHomePageResponse,
  IListComponent,
  IListResponse,
  IMediaComponent,
  IMediaResponse,
  INews,
  INewsResponse,
  IPartner,
  IPartnerResponse,
  IProjectResponse,
  IQuoteComponent,
  IRequestFormData,
  IReview,
  IReviewResponse,
  IServiceFeedback,
  IServiceFeedbackResponse,
  IStackComponent,
  IStackComponentResponse,
  IStep,
  IStepComponent,
  IStrapiAttributes,
  IStrapiData,
  IStrapiRichText,
  ITag, IVacancyListComponent,
  TComponent,
  THomeStepKey,
  THomeStepValue
} from '@redux/types';
import moment from 'moment';
import {YEAR_FORMAT} from '@common/constants';
import {IProject} from '@redux/projects/types';
import {MouseEvent} from 'react';
import { scroller } from 'react-scroll';


export const setRequestParams = (data: IParam | null | undefined): string => {
  if (!data || !Object.keys(data).length) {
    return ''
  }
  return '?' + new URLSearchParams(data).toString()
}

export const getPhoneNumbers = (phone: string) => {
  return parseInt(phone.replace(/[^0-9]/g, ''))
}

export const generatePersonalInformationText = (text: IStrapiRichText, classNames: string = 'simple-link simple-link--no-color simple-link--font-big') => {
  let title = ''
  text?.children?.forEach(d => {
    if (d.type === 'text') {
      title += d.text
    } else if (d.type === 'link') {
      if (d.children) {
        title += `<a class="${classNames}" href="${d.url}">${d.children[0].text}</a>`
      }
    }
  })
  return title
}

export const getSection = (page: string): string => {
  const sections: { [key: string]: string } = {
    'blog': 'blog', 'project': 'projects', 'vacancy': 'vacancies', 'service': 'services'
  }
  return sections[page]
}

export const generateStep = (data: TComponent[]): IStep[] => {
  let i = 0
  let steps: IStep[] = []
  let currentStep: IStep | undefined
  for (let s of data) {
    if (s.__component === EComponent.STEP) {
      if (currentStep) {
        steps.push(currentStep)
      }
      const step = s as IStepComponent
      currentStep = {
        count: step?.displayStepNumber ? ++i : i,
        step: {
          ...step,
          type: step?.type || EBlockType.grid
        },
        children: []
      }
    } else {
      if (currentStep) {
        let st = null
        if (s.__component === EComponent.MEDIA) {
          st = s as unknown as IMediaResponse
          st = {
            ...s,
            placement: {position: st?.position, type: st?.type},
            image: st?.file && 'data' in st.file ? st?.file?.data?.attributes || null : null,
            imageMobile: st?.imageMobile && 'data' in st.imageMobile ? st?.imageMobile?.data?.attributes || null : null
          } as IMediaComponent
        }
        if (s.__component === EComponent.LIST) {
          st = s as unknown as IListResponse
          st = {
            ...st,
            lists: st?.lists?.map(item => ({
              ...item,
              tags: item?.tags?.split(',') || []
            }))
          } as IListComponent
        }
        if (s.__component === EComponent.CASE_LIST) {
          st = s as unknown as ICaseListResponse
          st = {
            ...st,
            cases: st?.cases?.map(item => {
              const project = item?.project?.data?.attributes
              return {
                title: item?.title || project?.title || '',
                note: item?.note || project?.note || '',
                text: item?.text || [],
                description: project?.description || '',
                date: item?.date ? moment(item.date).format(YEAR_FORMAT) : project?.year ? moment(project?.year).format(YEAR_FORMAT) : null,
                tags: item?.tags?.data?.map(t => t.attributes) || project?.tags?.data?.map(t => t.attributes) || []
              }
            })
          } as ICaseListComponent
        }
        if (s.__component === EComponent.QUOTE) {
          st = s as unknown as IQuoteComponent
          st = {
            ...s,
            person: {
              ...st?.person,
              fullName: st?.person?.fullName || st.employee?.data?.attributes?.fullName || '',
              position: st?.person?.position || st.employee?.data?.attributes?.position || '',
              image: {
                data: st?.person?.image?.data || st.employee?.data?.attributes?.image?.data || null
              },
              imageMobile: {
                data: st?.person?.imageMobile?.data || st.employee?.data?.attributes?.imageMobile?.data || null
              },
            }
          } as unknown as IQuoteComponent
        }
        if (s.__component === EComponent.GRID) {
          st = s as IGridComponentResponse
          const cards = st?.cards.map((c: ICardResponse) => ({
            ...c,
            image: c.image?.data?.attributes ?? null,
            type: c.type?.data?.attributes?.type ?? null
          })) || []
          st = {
            ...s,
            cards
          } as IGridComponent
        }
        if (s.__component === EComponent.STACK_LIST) {
          st = s as IStackComponentResponse
          const stacks = st?.stacks.map((c: ICardResponse) => ({
            ...c,
            image: c.image?.data?.attributes ?? null,
          })) || []
          st = {
            ...s,
            stacks
          } as IStackComponent
        }
        currentStep.children.push(st ?? s)
      }
    }
  }
  if (currentStep) {
    steps.push(currentStep)
  }
  return !!steps.length ? steps : []
}

export const generateAwardsGrid = (data: IAwardResponse[]): { awards: IAward[], years: string[] } => {
  let awards: IAward[] = []
  let years: string[] = []
  let grid: IAward[] = []
  for (let award of data) {
    const year = award?.date ? moment(award.date).format(YEAR_FORMAT) : null
    if (year && !years.includes(year)) {
      years.push(year)
      award = {...award, id: `awards-year--${year}`}
    }
    if (award.isCard) {
      grid.push(award)
    } else {
      if (!!grid.length) {
        awards.push({
          title: '',
          description: '',
          grid: grid
        })
        grid = []
      }
      awards.push(award)
    }
  }
  if (!!grid.length) {
    awards.push({
      title: '',
      description: '',
      grid: grid
    })
  }
  return {awards, years: years.sort((a, b) => Number(b) - Number(a))}
}

export const generateStrapiQuery = (params: IStrapiQuery): string => {
  let query: string = ''
  const {fields, pagination, populate, sort, locale} = params
  if (!!fields?.length) {
    query += fields.map((f, i) => `fields[${i}]=${f}`).join('&')
  }
  if (!!populate?.length) {
    const p = `populate=${typeof populate === 'string' ? populate : populate.join(',')}`
    query = !!query.length ? [query, p].join('&') : p
  }
  if (!!sort?.length) {
    const s = sort.map((s, i) => `sort[${i}]=${s.key}%3A${s.direction}`).join('&')
    query = !!query.length ? [query, s].join('&') : s
  }
  if (pagination) {
    const pg = [Object.entries(pagination).map((k, v) => `pagination[${k[0]}]=${k[1]}`).join('&')]
    query = !!query.length ? [query, pg].join('&') : pg.toString()
  }
  if (locale) {
    query = [query, `locale=${locale}`].join('&')
  }
  return query
}

export const generateDetail = (detail: IEntityDetailResponse): IEntityDetail => {
  if (detail?.media?.file?.data) {
    const image = detail?.media?.file?.data?.attributes
    const imageMobile = detail?.media?.imageMobile?.data?.attributes
    return {
      ...detail, media: {
        ...detail.media,
        image: image ?? null,
        imageMobile: imageMobile ?? null
      }
    } as IEntityDetail
  }
  return detail as IEntityDetail
}

export const generateReviews = (reviews: IStrapiData<IStrapiAttributes<IReviewResponse>[]>): IReview[] => {
  return (reviews?.data?.map(r => r.attributes)?.map((r) => ({
    ...r,
    cardType: r?.cardType?.data?.attributes?.type as CardType || null,
    preview: r?.preview ? {
      video: r.preview?.video?.data?.attributes || null,
      image: r.preview?.image?.data?.attributes || null
    } : null
  })) || []) as IReview[]
}

export const generateServiceFeedback = (feedbacks: IServiceFeedbackResponse[]): IServiceFeedback[] => {
  return (feedbacks?.map((f) => {
      const r = f.review.data.attributes
      return {
        review: {
          ...r,
          cardType: r?.cardType?.data?.attributes?.type as CardType || null,
          preview: r?.preview ? {
            video: r?.preview?.video?.data?.attributes || null,
            image: r?.preview?.image?.data?.attributes || null
          } : null
        },
        cardType: f?.cardType?.data?.attributes?.type as CardType || null,
        color: f?.color || null
      }
    }
  ) || []) as IServiceFeedback[]
}

export const generateProject = (projects: IStrapiData<IStrapiAttributes<IProjectResponse>[]>): IProject[] => {
  return projects?.data.map(p => {
    const {attributes} = p
    const cardType = attributes?.cardType?.data?.attributes?.type

    return {
      ...attributes,
      cardType: cardType ? cardType as CardType : null,
      // year: attributes?.date,
      image: !attributes?.image?.data?.attributes?.url ? null : {
        ...attributes?.image?.data?.attributes,
      },
      imageMobile: !attributes?.imageMobile?.data?.attributes?.url ? null : {
        ...attributes?.imageMobile?.data?.attributes,
      },
      icon: !attributes?.icon?.data?.attributes?.url ? null : {
        ...attributes?.icon?.data?.attributes,
      },
      tags: attributes?.tags?.data?.map((s: { attributes: ITag }) => ({
        ...s.attributes
      })) ?? []
    }
  }) as IProject[]
}

export const generateNews = (news: IStrapiData<IStrapiAttributes<INewsResponse>[]>): INews[] => {
  return news?.data?.map(n => n.attributes).map(n => ({
    ...n,
    cardType: n?.cardType?.data?.attributes?.type as CardType || null,
    image: n?.image?.data?.attributes || null,
    imageMobile: n.imageMobile?.data?.attributes || null,
    case: n?.case?.data?.attributes?.slug || null
  })) || []
}

export const generateBlog = (blogs: IStrapiAttributes<IBlogResponse>[]): IBlog[] => {
  return blogs?.map(b => b.attributes).map(b => ({
    ...b,
    detail: b?.detail ? b?.detail as IEntityDetail : null,
    cardType: b.cardType.data?.attributes?.type as CardType || null,
    image: b.image?.data?.attributes || null,
    tag: b.tag.data?.attributes || null,
    steps: []
  })) || []
}

export const generateBlogDetail = (blog: IBlogResponse): IBlog => {
  const detail = blog.detail ? generateDetail(blog.detail) : null
  const steps = generateStep(blog.steps)
  return {
    ...blog,
    image: blog.image.data?.attributes || null,
    cardType: blog.cardType.data?.attributes.type as CardType || null,
    tag: blog.tag.data?.attributes || null,
    detail,
    steps
  }
}

export const generatePartnerGroup = (partners: IPartnerResponse[], locale: TLang): IPartner[] => {
  const emptyGroupName = locale === 'ru' ? 'Разное' : 'Miscellaneous'
  const grouped = partners?.reduce((r: { [key: string]: IPartnerResponse[] }, i: IPartnerResponse) => {
    r[i.tag.data?.attributes?.name ?? emptyGroupName] = r[i.tag.data?.attributes?.name ?? emptyGroupName] || []
    r[i.tag.data?.attributes?.name ?? emptyGroupName].push(i)
    return r
  }, {}) ?? []

  let groupedPartners: IPartner[] = []
  for (let key in grouped) {
    groupedPartners.push({
      name: key,
      group: grouped[key].map((g: IPartnerResponse) => ({
        ...g,
        logo: g.logo?.data?.attributes ?? null
      }))
    })
  }
  return groupedPartners
}

export const generateHomePage = (home: IHomePageResponse, locale: TLang): IHomePage => {
  const homeFormatted = {
    ...home,
    banner: home?.banner ? {
      ...home.banner,
      imageMobile: home.banner.imageMobile.data?.attributes || null,
      image: home.banner.image.data.attributes,
    } : null,
    steps: {
      services: home?.services ? {
        ...home.services,
        services: home.services.services?.data?.map(s => s.attributes)?.map(s => {
          const cardType = s?.cardType?.data?.attributes?.type
          return {
            ...s,
            cardType: cardType ? cardType as CardType : null
          }
        }) ?? []
      } : null,
      news: {
        ...home.news,
        news: home?.news?.news ? generateNews(home.news.news) : []
      },
      partners: home?.partners ? {
        ...home.partners,
        partners: !!home.partners.partners?.data.length ?
          generatePartnerGroup(home.partners?.partners?.data?.map(r => r.attributes), locale) : []
      } : null,
      cases: home?.cases ? {
        ...home.cases,
        projects: home?.cases.projects?.data?.map(p => p.attributes)?.map(p => ({
          ...p,
          cardType: p.cardType?.data?.attributes?.type as CardType ?? null,
          icon: p?.icon?.data?.attributes ?? null,
          image: p?.image?.data?.attributes ?? null,
          imageMobile: p?.imageMobile?.data?.attributes ?? null,
          tags: p?.tags?.data?.map((s: { attributes: ITag }) => ({
            ...s.attributes
          })) || []
        })) as IProject[] ?? []
      } : null,
      products: home?.products ? {
        ...home.products,
        products: home.products?.products?.map(p => ({
          ...p,
          image: p?.image?.data?.attributes ?? null,
          type: p?.type?.data?.attributes?.type as CardType ?? null
        } as unknown as ICard)) ?? []
      } : null,
      awards: home?.awards ? {
        ...home.awards,
        awards: home?.awards?.awards ? generateAwardsGrid(home?.awards?.awards?.data?.map(a => a.attributes)).awards : []
      } : null,
      reviews: home?.reviews ? {
        ...home.reviews,
        reviews: home?.reviews?.reviews ? home.reviews.reviews.map(r => ({
          ...r.review.data.attributes,
          cardType: r.cardType?.data?.attributes?.type ?? r.review.data?.attributes?.cardType?.data?.attributes.type ?? null,
          color: r.color ?? r.review.data.attributes.color,
          preview: r?.review.data.attributes.preview ? {
            video: r?.review.data.attributes.preview?.video?.data?.attributes || null,
            image: r?.review.data.attributes.preview?.image?.data?.attributes || null
          } : null
        })) : []
      } : null,
      slider: home.slider ? {
        ...home.slider,
        solutions: home.slider.solutions.data.map(s => s.attributes)
      } : null,
    }
  }

  return {
    ...homeFormatted,
    steps: Object.entries(homeFormatted?.steps)
      .map(o => ({key: o[0] as THomeStepKey, values: o[1] as THomeStepValue}))
      .filter(f => f?.values?.step)
      .sort((a, b) => a.values.index - b.values.index)
  } as IHomePage
}

export const getCardClassNames = (cardType: CardType | null, mainClass: string, isBig: boolean = true) => {
  const classNames = []
  if (!cardType && isBig) {
    classNames.push(mainClass + '--size-big')
  }
  if (cardType === 'rounded-right') {
    classNames.push(`${isBig ? mainClass + '--size-big ' : ''}` + mainClass + '--radius-right')
  }
  if (cardType === 'circle') {
    classNames.push(mainClass + '--round')
  }
  if (cardType === 'rounded') {
    classNames.push(mainClass + '--radius-all')
  }
  if (cardType === 'square') {
    classNames.push(mainClass + '--size-small')
  }
  return classNames
}

export const getDomainFromUrl = (url: string) => {
  const regex = /^(?:(?:http[s]?|ftp):\/)?\/?(?:[^:\/\s]+?\.)*([^:\/\s]+\.[^:\/\s]+)/
  return url.match(regex)?.[1] || null
}

export const scrollToElementById = (id: string) => {
  const element = document.getElementById(id)
  const header = document.getElementById('header')
  if (element) {
    scroller.scrollTo(id, {
      duration: 800,
      smooth: 'easeOutQuart',
      offset: -(header?.clientHeight ?? 0)
    })
  }
}

export const isNotEmptyRichContent = (items: any) => {
  if (!items) {
    return false;
  }
  return items?.hasOwnProperty('text') ? !!items?.text?.trim() : !!items?.some((c: any) => isNotEmptyRichContent(c?.children || c))
}

export const isListItemHaveLink = (items: any) => {
  return !!items?.every((c: any) => c.type === 'link' || (c.type !== 'link' && c.text === ''))
}

export const getRequestFormData = (payload: IRequestFormData): FormData => {
  const {nameContact, nameCompany, description, file, phone, email} = payload
  let formData = new FormData()
  formData.append('nameContact', nameContact ?? '')
  formData.append('nameCompany', nameCompany ?? '')
  if (description) {
    formData.append('description', description)
  }
  if (phone) {
    formData.append('phone', phone)
  }
  if (email) {
    formData.append('email', email)
  }
  if (file) {
    formData.append('file', file)
  }
  return formData
}

export const handleAnchorClick = (e: MouseEvent<HTMLAnchorElement>, url: string = '') => {
  const id = url?.includes('#') ? url.split('#').pop() : null
  if (id) {
    e.preventDefault()
    scrollToElementById(id)
  }
}

export function debounce<T extends any[]>(func: (...args: T) => any, timeout: number): (...args: T) => void {
  let timer: ReturnType<typeof setTimeout>
  return (...args: T) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

export const checkMobileSafari = () => {
  const agent = window.navigator.userAgent.toLowerCase()
  return /safari/.test(agent) && /iphone|ipod|ipad/.test(agent)
}

export const isCDN = () => process.env.NEXT_PUBLIC_CDN_URL !== undefined && process.env.NEXT_PUBLIC_DRAFT_MODE !== 'true'

export const getFromCDN = (src: string) => {
  return isCDN() ? `${process.env.NEXT_PUBLIC_CDN_URL}${src}` : src
}

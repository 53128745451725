import {
  IPersonalInformation,
  IPersonalInformationResponse,
  ISection,
  IStrapiAttributes,
  IStrapiData,
} from '@redux/types';
import {call, delay, put, takeEvery} from 'redux-saga/effects';
import api from '@api/index';
import * as actions from './actions';
import {EAppActionType, IAppRedirectGetAction, IAppSendRequestFormPostAction, IAppState} from '@redux/app/types';
import {generatePersonalInformationText, getRequestFormData} from '@common/functions';
import {REQUEST_FORM_DELAY} from '@common/constants';


function* getPersonalInformation() {
  try {
    const response: IStrapiData<IStrapiAttributes<IPersonalInformationResponse>> = yield call(() => api.get('/privacy-page', {
      query: {
        populate: '*'
      }
    }))
    const data = response.data.attributes
    const personalInformation: IPersonalInformation = {
      title: data.title,
      agreement: {
        title: generatePersonalInformationText(data.agreement.title[0]),
        description: generatePersonalInformationText(data.agreement.description[0])
      },
      conditions: data.conditions.map(d => generatePersonalInformationText(d, 'simple-link simple-link--no-color simple-link--no-underline'))
    }
    yield put(actions.setApp({
      personalInformation
    }))
  } catch (e) {
    console.log('Fetch failed, reason: ', e)
  }
}

function* getAppRedirect({ payload }: IAppRedirectGetAction) {
  try {
    const redirect: ISection = yield call(() => api.get(`/common/findBySlug/${payload.slug}`))
    yield put(actions.setApp({
      section: redirect
    }))
  } catch (e) {
    yield put(actions.setApp({
      section: null
    }))
    console.log('Fetch failed, reason: ', e)
  }
}

function* sendRequestForm({ payload }: IAppSendRequestFormPostAction) {
  const steps = payload.type === 'request' ? [
    {requestForm: {open: true, status: 'loading', isClear: false}},
    {requestForm: {open: true, status: 'success', isClear: false}},
    {requestForm: {open: false, status: null, isClear: true}},
    {requestForm: {open: true, status: 'error'}}
  ] : payload.type === 'block' ? [
    {blockForm: {status: 'loading', isClear: false}},
    {blockForm: {status: 'success', isClear: false}},
    {blockForm: {status: null, isClear: true}},
    {blockForm: {status: 'error'}}
  ] : [
    {modalForm: {open: true, form: payload.type, status: 'loading', isClear: false}},
    {modalForm: {open: true, form: payload.type, status: 'success', isClear: false}},
    {modalForm: {open: false, form: payload.type, status: null, isClear: true}},
    {modalForm: {open: true, form: payload.type, status: 'error'}}
  ]

  try {
    yield put(actions.setApp({
      ...steps[0] as IAppState
    }))
    const body = getRequestFormData(payload.data)
    yield call(() => api.post(`/common/sendRequestForm`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body
    }))
    yield put(actions.setApp({
      ...steps[1] as IAppState
    }))
    yield delay(REQUEST_FORM_DELAY)
    yield put(actions.setApp({
      ...steps[2] as IAppState
    }))
  } catch (e) {
    yield put(actions.setApp({
      ...steps[3] as IAppState
    }))
    console.log('Fetch failed, reason: ', e)
  }
}

export default [
  takeEvery(EAppActionType.GET_PERSONAL_INFORMATION, getPersonalInformation),
  takeEvery(EAppActionType.GET_APP_REDIRECT, getAppRedirect),
  takeEvery(EAppActionType.SEND_REQUEST_FORM, sendRequestForm),
]
